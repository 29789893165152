import React from "react";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";
import { Link, graphql } from "gatsby";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { CardTagButton } from "../page-components/blog/styles";

export default function TagsPage({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}: {
  data: {
    allMarkdownRemark: {
      group: {
        fieldValue: string;
        totalCount: number;
      }[];
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}) {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.TAGS}>
      <Seo
        title="Blog Tags | Robotic Imaging"
        description="Information center categorized. Come learn about Drones, LiDAR, BIM and more"
      />

      <header className="site__header">
        <h1 className="header__motto header__motto--no-margin">All Tags</h1>
      </header>

      <main className="content">
        <p>
          {group.map((tag) => (
            <CardTagButton>
              <small key={tag.fieldValue}>
                <Link
                  style={{ color: "#000" }}
                  to={`/tags/${kebabCase(tag.fieldValue)}/`}
                >
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </small>
            </CardTagButton>
          ))}
        </p>
      </main>
    </SiteWrapper>
  );
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
